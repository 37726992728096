<template>
  <v-card>
    <v-card-title class="text-h6">{{
      $t("labels.UID_lost_item")
    }}</v-card-title>
    <v-card-text>{{ $t("labels.enter_SKU_restock") }} </v-card-text>

    <v-card-text>
      <v-text-field
        v-model.number="code"
        type="number"
        class="c-input-small"
        @keyup.enter="confirm"
        :label="$t('labels.sku')"
        :placeholder="$t('labels.sku')"
        autofocus
        dense
        single-line
        outlined
        clearable
        hide-details
        append-icon="mdi-qrcode-scan"
        @click:append="showQRCodeScan('code')"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { httpClient } from "@/libs/http";
import { downloadExcelFile } from "@/libs/helpers";
export default {
  name: "DialogLostLabel",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    code: null,
    isLoading: true,
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    items() {
      return [...this.order.items];
    },
    allowCodes() {
      const allowCodes = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        allowCodes.push(item.sku);
        // allowCodes.push(`${item.customer_goods_barcode}`)
      }
      return allowCodes;
    },
  },
  methods: {
    downloadExcelFile,
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.confirm();
    },
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (!this.allowCodes.includes(this.code)) {
        this.$vToastify.error(
          this.$t("messages.wrong_code", { code: this.code })
        );
        this.code = null;
        return false;
      }

      const item = [...this.items].find((i) => i.sku == this.code);
      if (item.no_label || item.goods_uid_printed_on_item) {
        this.$vToastify.error(
          this.$t("messages.wrong_code", { code: this.code })
        );
        this.code = null;
        return false;
      }

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/generate-goods-uid",
          {
            quantity: 1,
            id_goods: item.id_goods,
            id_goods_issue_detail: this.order.id_goods_issue_detail,
            lot_tracking: null,
            expired_date: null,
          }
        );
        const { insertTracking } = data;
        this.isLoading = false;
        const stampSize = item.id_conformity_profile ? "50x40" : "50x20";
        await this.downloadExcelFile(
          `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-stamp`,
          {
            id_goods: item.id_goods,
            insert_tracking: insertTracking,
            client_time: moment().format("HH:mm DD/MM/YYYY"),
          },
          `${stampSize}_${item.sku}_${insertTracking}.pdf`
        );

        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
